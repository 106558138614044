import React, {lazy, Suspense} from 'react'
import './index.css';
import CircularProgress from "@mui/material/CircularProgress";
import * as ReactDOM from 'react-dom'
import ErrorBoundary from './components/ErrorBoundary'
import GlobalStyles from './style'
import styled from "styled-components";

const Center = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const initializingApplication = () => (
  <Center>
    <CircularProgress color="primary"/>
    <br/>
    Initializing
  </Center>
)

export const render = (
  Component: any,
  el = document.getElementById('root') as HTMLElement
) => {
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Suspense fallback={initializingApplication()}>
          <GlobalStyles/>
          <Component env={window.env}/>
        </Suspense>
      </ErrorBoundary>
    </React.StrictMode>,
    el
  )
}

if (process.env.NODE_ENV !== 'test') {
  const App = lazy(() => import('./components/App' /* webpackChunkName: "App" */))
  render(App)
}

if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
  (module as any).hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default
    render(NextApp)
  })
}